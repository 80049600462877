import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/PageHeader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-8a.png';
import image2 from 'images/project-8b.png';
import image3 from 'images/project-8c.png';
import image4 from 'images/project-8d.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="Safe haven"
      meta={[
        {
          name: 'description',
          content:
            'Sharron is the primary caregiver for her special needs grandson and when her landlord gave her notice to move out she felt she was &lsquo;out of options&rsquo;.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Safe haven"
      intro="Sharron is the primary caregiver for her special needs grandson and when her landlord gave her notice to move out she felt she was &lsquo;out of options&rsquo;."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            Reluctant to leave her Hutt Valley suburb (her grandson was well-established at the
            local school, and changes are difficult for him to manage), Sharron cashed up her
            Kiwisaver and bought a property she could just afford in the same area.
          </Paragraph>
          <Paragraph>
            At the time, the home required urgent maintenance; the roof was leaking, allowing water
            to flow into the ceilings and causing the tiles to slump and drip above her bed. Her
            kitchen was in a state of disrepair, with broken kitchen joinery, patchy holes and
            little storage space. There were more dangerous holes in her bathroom floor and even
            worse&ndash; the toilet moved! To add to the problems, a cold, damp and leaky concrete
            block wall stretched across the two bedrooms.
          </Paragraph>
          <Paragraph>
            Sharron&rsquo;s daughter noticed the pain and frustration her mother was experiencing
            and sought help from Ryhaven Trust to tackle the worst of these issues.
          </Paragraph>
          <Paragraph>
            Systematically, areas were fixed by the Ryhaven team. Externally, they repaired holes in
            the roof and Ryhaven builder, Bernard created an accessible laundry enclosed on the back
            porch so that Sharron didn&rsquo;t need to haul washing up and down a path to the
            basement.
          </Paragraph>
          <Paragraph>
            Inside, the house has been transformed, featuring new insulated ceilings, additional
            bathroom fixtures, and a refreshed kitchen with brand new pantry, benchtops and
            flooring. In the bedrooms, a new gib layer was installed atop the former bare concrete
            block wall and all the walls in the home have been repaired and freshly painted. It
            looks and feels like a different home.
          </Paragraph>
          <Paragraph>
            When Sharron&rsquo;s 21-year-old son unexpectedly moved home, Ryhaven also provided a
            cabin for him to stay in. &ldquo;The small rent we pay for the cabin has meant that my
            son has been able to stay at home and get back on his feet.&rdquo;
          </Paragraph>
          <Paragraph>
            Sharron says it is a huge thing to be able to have people in her home now and not feel
            embarrassed. She has nothing but praise for Ryhaven&rsquo;s contribution in bringing
            these changes about and makes special mention of Bernard in his role. &ldquo;Without
            them (Ryhaven) we would still be in an incredibly dangerous and unhealthy home.&rdquo;
          </Paragraph>
          <Paragraph>
            Photography: Russell Finnerty
            <br />
            Words: Gill Burns
          </Paragraph>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
          <img className={styles.image} src={image4} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
